import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer as HotContainer } from 'react-hot-loader';
import { Route, Router } from 'react-router-dom';
import { Switch } from 'react-router';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Auth0Provider, AppState } from '@auth0/auth0-react';
import { IntlProvider } from 'react-intl';
import ErrorBoundary from './components/ErrorBoundary';
import { history } from './graphql/client';
import engTranslations from '../../../../kyyti-i18n/kyyti-app/locales/en';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

if (window.env.BUGSNAG_APIKEY) {
	Bugsnag.start({
		apiKey: window.env.BUGSNAG_APIKEY,
		plugins: [new BugsnagPluginReact()],
		enabledReleaseStages: ['development', 'production'],
		releaseStage: window.env.ENV
	});
}

const BugsnagErrorBoundary = window.env.BUGSNAG_APIKEY
	? Bugsnag.getPlugin('react').createErrorBoundary(React)
	: React.Fragment;

// React-intl requires all translation keys to be flat (not deep json objects)
const flatTranslations = Object.keys(engTranslations).reduce<any>((obj, key) => {
	const translations = engTranslations[key as keyof typeof engTranslations];
	for (const id of Object.keys(translations)) {
		const newKey = [key, id].join('.');
		obj[newKey] = translations[id as keyof typeof translations];
	}

	return obj;
}, {});

const getRouter = async () => {
	const { default: App } = await import(/* webpackChunkName: "App" */ './components/App');
	const { default: theme } = await import(/* webpackChunkName: "theme" */ './default-theme');

	return (
		<MuiThemeProvider theme={theme}>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<Router history={history}>
					<Switch>
						<Route component={App} />
					</Switch>
				</Router>
			</MuiPickersUtilsProvider>
		</MuiThemeProvider>
	);
};

const onRedirectCallback = (appState: AppState) => {
	history.push(appState?.returnTo || window.location.pathname);
};

const render = (elem: JSX.Element) => {
	ReactDOM.render(
		<BugsnagErrorBoundary>
			<ErrorBoundary>
				<Auth0Provider
					useRefreshTokens
					useCookiesForTransactions
					domain={window.env.AUTH0_DOMAIN}
					clientId={window.env.AUTH0_CLIENTID}
					redirectUri={window.location.origin}
					cacheLocation="localstorage"
					onRedirectCallback={onRedirectCallback}
				>
					<IntlProvider locale="en" messages={flatTranslations}>
						<HotContainer>{elem}</HotContainer>
					</IntlProvider>
				</Auth0Provider>
			</ErrorBoundary>
		</BugsnagErrorBoundary>,
		document.querySelector('#content')
	);
};

if ((module as any).hot) {
	(module as any).hot.accept('./components/App', () => getRouter().then(render));
	(module as any).hot.accept('./default-theme', () => getRouter().then(render));
}

getRouter().then(render);
