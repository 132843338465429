export default {
  login: {
    title: 'Hello!',
    description: 'Enter your email and activate the app',
    sendEmailAgree:
      'By entering your email you sign up to the service and agree to the {{termsOfUse}} and {{privacyPolicy}}',
    termsOfUse: 'terms of use',
    privacyPolicy: 'privacy policy.',
    sendEmailButton: 'SEND',
    emailInputPlaceholder: 'enter email address',
    errorWrongEmailTitle: 'Invalid email',
    errorWrongEmailDescription: 'Please check your email address',
    errorGeneralEmailTitle: 'Something went wrong',
    errorGeneralEmailDescription: 'Please try again later',
    loginErrorTitle: 'Failed to login',
    loginErrorDescription: 'Link or pin code was expired or invalid',
    openEmailAppErrorTitle: 'Error',
    openEmailAppErrorDescription: "Unfortunately we couldn't open your email app",
    openEmailAppErrorConfirm: 'Alright, I open it myself',
  },
  emailSent: {
    title: 'Check your mail',
    description: 'No password required, just tap the link in the mail we send to {{email}}',
    openMailAppButton: 'OPEN MAIL APP',
    openMailAppButtonIos: 'OPEN MAIL APP',
    problemsHelp: 'Problems? {{pinCodeLink}}',
    problemsHelpPinCode: 'Enter pin code from the mail.',
    loggingIn: 'Logging in',
  },
  pinCodeLogin: {
    title: 'Enter pin code',
    description: 'Enter pin code from the mail we sent to you',
    pinCodeInputPlaceholder: 'enter pin code',
    loginButton: 'SIGN IN',
  },
  main: {
    textInputPlaceholder: 'Where to?',
    textInputPlaceholderNoGps: 'From?',
    gpsErrorTitle: 'Location services turned off',
    gpsErrorDescription:
      'Turn on location services in your device settings to improve pickup experience',
    gpsErrorTurnOnButton: 'Turn on location services',
    gpsErrorAskPermissionButton: 'Allow location services',
    homeIconLabel: 'Home',
    workIconLabel: 'Work',
    repeatIconLabel: 'Repeat',
    updateModalTitle: 'Update available',
    updateModalDescription: "Don't worry! We will install the update for you.",
    updateModalConfirmButton: 'Update',
    nonMandatoryUpdateModalDescription: "Don't worry! We will install the update for you.",
    nonMandatoryUpdateModalInstallButton: 'Update',
    nonMandatoryUpdateModalIgnoreButton: 'Skip',
  },
  drawer: {
    accountItemLabel: 'Account',
    tripsTicketsItemLabel: 'Trips & Tickets',
    tripsItemLabel: 'Trips',
    orderHistoryItemLabel: 'Order history',
    paymentItemLabel: 'Payment methods',
    enterCode: 'Enter code',
    helpCenterLabel: 'Help',
    customerSupportLabel: 'Customer support',
    aboutLabel: 'About',
  },
  placeSearch: {
    fromInputPlaceholderWithLocation: 'Current location',
    fromInputPlaceholder: 'From',
    toInputPlaceholder: 'Where to?',
  },
  confirmPickup: {
    textInputValueLoading: 'Loading...',
    textInputValueUnknownAddress: 'Not known address',
    title: 'My departure point:',
    note: '...or move the point on the map.',
    confirmButton: 'CONFIRM DEPARTURE POINT',
  },
  confirmTrip: {
    passengerModalLabel: 'Passengers',
    luggageModalLabel: 'Luggage',
    swipeButtonLabel: 'SWIPE TO ORDER',
    swipeButtonLabelTicketNotAvailable: 'Route has no options for sale',
    timePickerTitle: 'Choose your timetable',
    timePickerSetButton: 'SET',
    timePickerDepartureNowLabel: 'As soon as possible',
    timePickerDepartureAtLabel: 'Depart earliest at',
    timePickerArriveAtLabel: 'Arrive latest by',
    numberInputSetButton: 'SET',
    selectedTimeDeparture: 'Depart earliest on {{date}} at {{time}}',
    selectedTimeArrival: 'Arrive latest by {{date}} at {{time}}',
    confirmingTitle: 'One moment',
    confirmingTitleError: 'Oh no!',
    confirmingDescription: 'We are processing your order',
    confirmingErrorBackButton: 'GO BACK',
    missingCreditCardError: 'Add payment method',
    routePickerTitle: 'SELECT ONE OF',
    errorPrimaryGeneric: 'Something went wrong',
    errorSecondaryGeneric: 'Please, make a new search',
    addPhoneNumberTitle: 'PHONE NUMBER NEEDED',
    phoneNumberExplanation: "If driver can't find you he can call you",
    phoneNumberAddButton: 'ADD PHONE NUMBER',
    phoneNumberSkipButton: 'SKIP THIS TIME',
    phoneNumber: 'Phone number',
    phoneNumberPlaceholder: 'Enter your phone number',
    orderErrorPaymentFailed: 'Payment charge failed. Sorry about that!',
    orderErrorPaymentMethodNotAllowed: 'Sorry, your payment method is not allowed.',
    orderErrorPaymentMethodNotEnoughCredit: "Sorry, you don't have enough credit",
    orderErrorAccountLocked: 'Payment account frozen. Is your card OK?',
    orderErrorTemporarilyUnavailable:
      'Payment access is having problems and we could not process your payment.',
    orderErrorUnfulfillable: 'It seems someone managed to book that Kyyti before you',
    orderErrorPast: 'Kyyti reservation expired, try to create new search',
    orderErrorTooFar: 'You searched a ride too far in future',
    orderErrorGeneral: 'Something went wrong during payment or trip booking. We will investigate.',
    errorKyytiPrimaryNoCarsAvailable: 'All cars are busy serving customers',
    errorKyytiSecondaryNoCarsAvailable: 'Please try search on another time',
    errorKyytiPrimaryServiceClosed: 'Service is not available at this hour',
    errorKyytiSecondaryServiceClosed: 'Please try search on another time',
    errorKyytiSecondaryTapRouteOptions: 'Tap here for route options and service times',
    errorKyytiPrimarySearchInPast: 'You searched a ride in the past',
    errorKyytiSecondarySearchInPast: 'Please, change the time',
    errorKyytiPrimarySearchInFuture: 'You searched a ride too far in future',
    errorKyytiSecondarySearchInFuture: 'Please, change the time',
    errorKyytiPrimaryTooShortTrip: 'Too short for Kyyti',
    errorKyytiSecondaryTooShortTrip: "We don't offer rides for trips that are too short",
    errorKyytiPrimaryGeneric: 'Something went wrong',
    errorKyytiSecondaryGeneric: 'Please, make a new search',
    errorKyytiPrimaryOutsideOfServiceArea: 'Outside the service area',
    errorKyytiSecondaryOutsideOfServiceArea: "Sorry, we don't offer this route yet",
    errorKyytiServiceTimesNoCarsAvailable: 'Show free options at {{time}}', // 'free' here refers to available car, not free of charge. Use short text to fit the screen.
    errorKyytiServiceTimesServiceClosed: 'Show alternate service at {{time}}',
    tavelModeNameWalk: 'Walk',
    tavelModeNameBicycle: 'Bicycle',
    normalPrice: 'Price before rebate {{normalPriceFormatted}}',
    purchaseTicket: 'BUY TICKET',
    routeInfo: 'ROUTE INFO',
  },
  paymentMethods: {
    title: 'Payment methods',
    addPaymentMethodButton: 'Add payment method',
    addVoucherCreditButton: 'ADD CREDIT CODE',
    addPaymentMethodError: 'Failed to add payment method',
    removePaymentMethodError: 'Failed to remove payment method',
    paymentCardRemoveButton: 'Remove',
    noPaymentMethods: 'No payment methods',
    noteAboutProvider:
      'Payment card information stored securely. Payment services provided by {{providers}}.',
    expiresSoon: 'Expires soon - update payment method',
    addVoucherCode: 'ADD CODE', // 'code' refers to coupon code. Keep this text short to fit screen.
  },
  addPaymentMethod: {
    title: 'Add payment method',
    addButton: 'Add card',
    nameTitle: 'Name',
    namePlaceholder: "Cardholder's name",
    cardTitle: 'Card number',
  },
  addVoucherCredit: {
    title: 'Add code',
    addButton: 'ADD',
    inputPlaceholder: 'Add your code',
    infoText: 'Enter code to add discount or balance to your account',
    successTitle: 'Account credited!',
    successDescription: 'You can now use following balance as payment',
  },
  orderHistory: {
    title: 'Order history',
    noOrdersDone: 'No orders yet, go make one!',
    loadMoreButton: 'Load more orders',
  },
  receipt: {
    title: 'RECEIPT',
    errorTitle: 'We could not get your receipt',
    receiptSentToEmail: 'Receipt sent to email',
  },
  trips: {
    title: 'Trips & Tickets',
    titleTrips: 'Trips',
    noTrips: 'No upcoming orders. Go on a trip!',
    tripFromAddressLabel: 'From:',
    tripToAddressLabel: 'To:',
    openOrderHistory: 'SHOW ORDER HISTORY',
    serviceSelectorTitle: 'PURCHASE TRIP',
    activeTickets: 'Active tickets',
    pastTickets: 'Past tickets',
    noActiveTickets: 'No active tickets at the moment.',
  },
  buyTickets: {
    title: 'BUY TICKET: {{serviceName}}',
    titleWithoutService: 'BUY TICKET',
    emptyListPlaceholder: 'No tickets available',
    zoneSelectorHeader: 'SELECT ZONES',
  },
  confirmTicket: {
    confirmOrderButton: 'SWIPE TO ORDER',
    openTermsButton: 'TO CONTINUE, ACCEPT TERMS OF SERVICE',
    stillValidProductFoundTitle: 'Existing valid product',
    stillValidProductFoundDescription:
      'You still have same valid ticket that you are buying at the moment. Do you want to continue?',
    stillValidProductFoundYesButton: 'Yes',
    stillValidProductFoundNoButton: 'No',
    zoneDescription: {
      one: 'Zone {{zones}}',
      other: 'Zones {{zones}}',
    },
    normalPrice: 'Price before rebate {{normalPriceFormatted}}',
  },
  confirmOrder: {
    swipeButtonHint: 'RELEASE TO CONFIRM',
  },
  feedback: {
    completedTitle: 'Kyyti says thanks!',
    sendFeedbackButton: 'SEND FEEDBACK',
  },
  tripCancel: {
    okButton: 'OK',
    cancelDescriptionTitle: 'Cancelled',
    cancelDescriptionMessage: 'Your trip has been cancelled',
  },
  onTrip: {
    vehicleIdLabel: 'VEHICLE: {{vehicleId}}',
    statusHeaderReceived: 'Order confirmed',
    statusHeaderPendingDelivery: 'Awaiting vehicle confirmation',
    statusHeaderPendingDeliveryWithVehicle: 'Order confirmed',
    statusHeaderOngoing: 'Enjoy your ride',
    statusHeaderDelivery: 'Your Kyyti is en route to pickup',
    statusHeaderComplete: 'Trip has completed',
    estimatedTimePickup: 'Estimated time of pickup',
    destination: 'Estimated arrival',
    cancelConfirmTitle: 'Do you want to cancel this order?',
    cancelConfirmTitleCharge: 'Order is confirmed. Do you want to cancel anyway?',
    cancelConfirmMessage: 'An unconfirmed order can be cancelled free of charge.',
    cancelConfirmMessageCharge: 'Cancellation fee of {{formattedPrice}} will be charged.',
    cancelConfirmOkay: 'Yes',
    cancelConfirmDecline: 'No',
    cancelButton: 'CANCEL',
    callToDriverButton: 'CALL DRIVER',
    customerSupportButton: 'CUSTOMER SUPPORT',
    cancelErrorTitle: 'Cancel error',
    cancelErrorDescription: 'This order can no longer be canceled',
    ticketHeader: 'YOUR TICKET',
    ticketOrderNumber: 'ORDER NUMBER',
    ticketPassengerQuantity: {
      one: '{{count}} passenger',
      other: '{{count}} passengers',
    },
    ticketLuggageQuantity: {
      zero: 'carry on only',
      one: '{{count}} luggage',
      other: '{{count}} luggages',
    },
    ticketCloseButton: 'Close',
    orderNotFoundTitle: 'ERROR',
    orderNotFoundDescription: 'Order was not found',
    tripErrorGenericTitle: 'SOMETHING WENT WRONG',
    tripErrorGenericDescription: 'Try again later',
    driverExtraInfo: 'PICKUP INFO FOR DRIVER',
    driverExtraInfoTextPlaceHolder: 'Add pickup information for driver',
    driverExtraInfoSaveButton: 'SAVE',
    driverExtraInfoErrorTitle: 'Error',
    driverExtraInfoErrorMessage: 'Failed to add pickup extra info',
  },
  account: {
    title: 'ACCOUNT',
    firstName: 'First name',
    firstNamePlaceholder: 'Enter your first name',
    lastName: 'Last name',
    lastNamePlaceholder: 'Enter your last name',
    emails: 'Emails',
    phoneNumber: 'Phone number',
    phoneNumberPlaceholder: 'Enter your phone number',
    validationErrorSame: 'No same name',
    validationErrorFieldRequired: 'Required',
    validationErrorEmailInvalid: 'Enter valid email address',
    validationErrorPhoneCountryCode: 'Enter number with + and country code',
    validationErrorPhoneInvalid: "Phone number doesn't appear valid, please check",
    dataSaveFailed: 'Save failed',
    logoutButton: 'LOG OUT',
    logoutErrorTitle: 'Error',
    logoutErrorDescription: 'Logout failed, please try again later.',
    saveButton: 'Save',
    voucherListItemLabel: 'Credit balance',
    redeemVoucherListItemLabel: 'Add promo code',
    joinGroupItemLabel: 'Join a group',
    groupListItemLabel: 'Groups',
    groupsNumberOfMissingInfo: {
      one: '{{count}} group is missing info',
      other: '{{count}} groups are missing info',
    },
    joinGroupDescription:
      'To join a group, ask the group owner for the instructions including a group code to enter above. Please note that you may need to provide additional membership validation information in the next step.',
  },
  emails: {
    removeEmailError: 'Something went wrong when removing email',
    emailsTitle: 'Emails',
    addEmail: 'Add email',
    emailNotVerified: 'Unverified',
    primaryEmailDescription: 'We use this email to communicate with you',
    removeEmailButton: 'Remove',
    moreButton: 'More',
    moreItemResend: 'Resend email confirmation',
    moreItemCancel: 'Cancel',
    resendSuccess: 'We have sent you the confirmation mail',
    resendError: "We can't send email confirmation to that address",
  },
  addEmail: {
    dataSaveFailed: 'Save failed',
    addEmailTitle: 'Add email',
    email: 'Email',
    emailPlaceholder: 'Enter your email',
    addEmailButton: 'SUBMIT',
  },
  groups: {
    title: 'GROUPS',
    noGroups: 'You are not in any group yet',
    updatedGroupInfoTitle: 'Group info updated',
    updatedGroupInfoDescription: 'Group info updated successfully',
    groupMissingInfo: 'Info is missing',
    leaveGroupButton: 'Leave',
    leaveGroupError: 'Something went wrong while leaving group',
  },
  groupUpdateInfo: {
    title: 'UPDATE GROUP INFO',
    fillAllFieldsErrorTitle: 'Fill all fields',
    fillAllFieldsErrorDescription: 'Please fill all fields in order to proceed',
    updateFailedGenericTitle: 'Failed to update',
    updateFailedGenericDescription:
      'Something went wrong when updating group info. Try again later.',
  },
  joinGroup: {
    successTitle: 'You have joined group',
    successDescription: 'You have successfully joined the group',
    errorNotFoundDescription: 'Group was not found',
    errorDuplicateDescription: 'You have already joined this group',
    errorGenericDescription: 'Try again later',
    errorCampaignEndedDescription: "You can't join this group anymore",
    title: 'Join a group',
    inputPlaceholder: 'Enter group code',
    joinGroupButton: 'JOIN',
    addMissingInfoButton: 'ADD MISSING INFO',
    infoMissingLabel: 'You need to add additional membership validation information.',
  },
  vouchers: {
    voucherValidUntil: 'Valid until {{validUntil}}',
    noVouchers: "You don't have any credits yet",
    vouchersTitle: 'Credit balance',
  },
  voucherAdd: {
    successTitle: 'Discount available!',
    successDescription: 'Your next purchase is eligible for following discount',
    errorNotFoundDescription: 'This code is invalid',
    errorDuplicateDescription: 'You already used this code',
    errorCampaignEndedDescription: "You can't use this code anymore",
    errorGenericDescription: 'Try again later',
  },
  about: {
    title: 'ABOUT',
    leagalHeader: 'Legal',
    privacyPolicyLabel: 'PRIVACY POLICY',
    termsOfUseLabel: 'TERMS OF USE',
  },
  ticket: {
    activateTicketButton: 'ACTIVATE TICKET',
    ticketExpired: 'Ticket expired',
    customerInfoTitle: 'Customer',
    orderInfoTitle: 'Order',
    ticketInfoTitle: 'Ticket details',
    errorFailedToActivate: 'Failed to activate ticket',
    errorTransferNotPossible:
      'This ticket cannot be opened with this device. Check the conditions when you can switch ticket to a new device.',
    errorGeneral: 'Failed to load ticket',
    userIdLabel: 'Id:',
    userPhoneNumberLabel: 'Phone number:',
    orderId: 'Id:',
    reloadOrder: 'Try again',
    validUntil: 'Valid until:',
    inspectTicketButton: 'TICKET INSPECTION',
    unsupportedTicketDescription:
      'This ticket is not supported by current application version. Please update application.',
    updateAppButton: 'Update',
  },
  acceptTermsModal: {
    title: 'Terms of service',
    acceptButton: 'ACCEPT',
    acceptButtonMulti: 'ACCEPT {{current}} / {{total}}',
  },
  notification: {
    androidChannelName: 'Order updates',
    androidChannelDescription: 'Status updates about your order',
  },
  errorScreen: {
    genericMessage: 'Something went wrong',
    genericRefreshText: 'Try again',
  },
  url: {
    // do not change any of the urls
    kyytiPrivacyPolicy: 'http://www.kyyti.com/privacy.html',
    kyytiTermsOfUse: 'http://www.kyyti.com/terms.html',
  },
  distance: {
    kilometerAbbreviation: 'km',
    meterAbbreviation: 'm',
    mileAbbreviation: 'mi',
    footAbbreviation: 'ft',
  },
  time: {
    twelveHourFormat: 'h:mm A', // change only time separator ":" if needed. For example in some languages time separator is dot "."
    twentyFourHourFormat: 'HH:mm', // change only time separator ":" if needed. For example in some languages time separator is dot "."
    meridiemAM: 'AM',
    meridiemPM: 'PM',
    hourAbbreviation: 'h',
    minuteAbbreviation: 'min',
    connectionTime: 'Connection time {{time}}',
  },
  routeInfo: {
    direction: 'Direction: {{headingTo}}',
    today: 'Today',
    tomorrow: 'Tomorrow',
    yesterday: 'Yesterday',
  },
};
