import { defaultDataIdFromObject, InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloLink, split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import storage from '@/lib/session-storage';
import { onError } from 'apollo-link-error';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { ApolloClient, ApolloClientOptions, Resolvers } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import fetch from 'node-fetch';
import createHistory from 'history/createBrowserHistory';
import defaults from './defaults';
import resolvers from './resolvers';
import typeDefs from './type-defs';

export const history = createHistory();

function getLinks(auth0: Auth0ContextInterface) {
	const errorLink = onError(({ networkError }) => {
		if (networkError && (networkError as any).statusCode === 401) {
			auth0.logout();
		} else {
			// eslint-disable-next-line no-console
			console.error(networkError);
		}
	});

	const httpRequestLink = createHttpLink({
		uri: `${window.env.JSON_API_URL}/graphql`,
		credentials: 'include',
		fetch: async (uri: any, options: any) => {
			const res = await fetch(uri, options);
			const token = res.headers.get('x-xsrf-token');
			if (token) {
				storage.nonce.set(token);
			}

			return Promise.resolve(res) as any;
		}
	});

	const authLink = setContext(async (_, { headers }) => {
		const xsrf = storage.nonce.get();
		const claims = await auth0.getIdTokenClaims();

		return {
			headers: {
				...headers,
				'X-XSRF-Token': xsrf,
				Authorization: `Bearer ${claims.__raw}`
			}
		};
	});

	const httpLink = ApolloLink.from([authLink, httpRequestLink]);

	const wsLink = new WebSocketLink({
		uri: `${window.env.WS_URL}/graphql`,
		options: {
			reconnect: true,
			connectionParams: async () => {
				const claims = await auth0.getIdTokenClaims();
				return {
					Authorization: `Bearer ${claims.__raw}`
				};
			},
			connectionCallback: err => {
				if (err) {
					console.error(err); // eslint-disable-line no-console
				} else {
					console.log('WebSocket connected'); // eslint-disable-line no-console
				}
			},
			// Connect only when first subscription is created to prevent unauthenticated connection
			lazy: true
		}
	});

	const networkLink = split(
		// Split based on operation type
		({ query }) => {
			const definition = getMainDefinition(query);
			return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
		},
		wsLink,
		httpLink
	);

	return ApolloLink.from([errorLink, networkLink]);
}

function getApolloClientConfig(auth0: Auth0ContextInterface): ApolloClientOptions<NormalizedCacheObject> {
	const typenameBlacklist = new Set(['OrderPayment', 'ProductWithDelivery']);
	const cache = new InMemoryCache({
		dataIdFromObject: object =>
			object.__typename && typenameBlacklist.has(object.__typename) ? null : defaultDataIdFromObject(object)
	});
	cache.writeData({ data: defaults(history) });

	return {
		link: getLinks(auth0),
		cache,
		resolvers: resolvers(history, cache) as Resolvers,
		typeDefs,
		connectToDevTools: false
	};
}

let apolloClient: ApolloClient<NormalizedCacheObject>;

export const getClient = (auth0: Auth0ContextInterface) => {
	if (!apolloClient) {
		apolloClient = new ApolloClient(getApolloClientConfig(auth0));
	}

	return apolloClient;
};
